/* global require */
require.config({
    deps: ['vendors/pvt-bower/config'],
    callback: function () {
        'use strict';
        require(['site/main']);
    },
    paths: {
    },
    shim: {
    }
});
define("site-config", function(){});


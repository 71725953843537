/* global define, window */
define('scope',['underscoredeep'], function (_) {
    'use strict';
    var scope = window.INGRIDHEIMBECK = window.INGRIDHEIMBECK || {};
    scope.register = function (key, value) {
        _.deep(scope, key, value);
        return value;
    };
    return scope;
});
